<template>
  <div class="flex flex-col">
    <div class="wizard">
      <nav aria-label="Progress">
        <ol
          class="border border-gray-300 rounded divide-y divide-gray-300 md:flex md:divide-y-0"
        >
          <li
            class="relative md:flex-1 md:flex"
            v-for="(step, index) in wizard.steps"
            :key="`step-${index}`"
          >
            <a
              v-if="wizard.currentStep > index + 1"
              href="#"
              class="group flex items-center w-full"
            >
              <span class="p-2 flex items-center text-xs font-medium">
                <span
                  class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
                >
                  <svg
                    class="w-6 h-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span class="ml-4 text-xs font-medium text-gray-900">{{
                  step.title
                }}</span>
              </span>
            </a>

            <a
              href="#"
              class="p-2 flex items-center text-xs font-medium"
              aria-current="step"
              v-else
            >
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full"
              >
                <span class="text-indigo-600">0{{ index + 1 }}</span>
              </span>
              <span class="ml-4 text-xs font-medium text-indigo-600">{{
                step.title
              }}</span>
            </a>

            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
        </ol>
      </nav>

      <div class="pt-8 max-h-96 overflow-y-auto text-left">
        <div v-show="wizard.currentStep == 1" class="flex">
          <div class="w-full">
            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="template"
                  class="block text-sm font-medium text-gray-700"
                >
                  Vorlage
                </label>
                <div class="w-full">
                  <select
                    v-model="$v.performanceReview.template.$model"
                    class="w-full text-xs border p-2 rounded"
                    :class="{
                      'border-red-500': $v.performanceReview.template.$error,
                    }"
                  >
                    <option value="">Vorlage auswählen</option>
                    <option
                      v-for="(template, index) in templates"
                      :value="template"
                      :key="`template-${index}`"
                    >
                      {{ template.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col w-full m-auto"
          v-show="wizard.currentStep == 2"
        >
          <div class="w-full flex flex-col space-y-4">
            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="employee"
                  class="block text-sm font-medium text-gray-700"
                >
                  Mitarbeitende/r
                </label>
                <div class="w-full">
                  <select
                    v-model="$v.performanceReview.employee.$model"
                    class="w-full text-xs border p-2 rounded"
                    :class="{
                      'border-red-500': $v.performanceReview.employee.$error,
                    }"
                  >
                    <option value="">Mitarbeitende/r auswählen</option>
                    <option
                      v-for="(employee, index) in employees"
                      :value="employee"
                      :key="`employee-${index}`"
                    >
                      {{ employee.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="employee"
                  class="block text-sm font-medium text-gray-700"
                >
                  Rollenvorgesetzte/r
                </label>
                <div class="w-full">
                  <select
                    v-model="$v.performanceReview.supervisor.$model"
                    class="w-full text-xs border p-2 rounded"
                    :class="{
                      'border-red-500': $v.performanceReview.supervisor.$error,
                    }"
                  >
                    <option value="">Rollenvorgesetzte/r auswählen</option>
                    <option
                      v-for="(supervisor, index) in supervisors"
                      :value="supervisor"
                      :key="`supervisor-${index}`"
                    >
                      {{ supervisor.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col w-full m-auto"
          v-show="wizard.currentStep == 3"
        >
          <div class="w-full space-y-3">
            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="employee"
                  class="block text-sm font-medium text-gray-700"
                >
                  Beurteilungsperiode
                </label>
                <div class="w-full">
                  <date-picker
                    v-model="$v.performanceReview.evaluation_period.$model"
                    :input-class="
                      `
                      ${
                        $v.performanceReview.evaluation_period.$error
                          ? 'border-red-500'
                          : ''
                      } 
                      border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent`
                    "
                    @clear="
                      $v.performanceReview.evaluation_period.$model = null
                    "
                    placeholder="Beurteilungsperiode eingeben"
                    range
                  />
                </div>
              </div>
            </div>

            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="employee"
                  class="block text-sm font-medium text-gray-700"
                >
                  Vorbereitung Deadline
                </label>
                <div class="w-full">
                  <date-picker
                    v-model="$v.performanceReview.preparation_deadline.$model"
                    :input-class="
                      `${
                        $v.performanceReview.preparation_deadline.$error
                          ? 'border-red-500'
                          : ''
                      } 
                      border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent`
                    "
                    placeholder="Deadline für Vorbereitung eingeben"
                  />
                </div>
              </div>
            </div>

            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="employee"
                  class="block text-sm font-medium text-gray-700"
                >
                  Gespräch Deadline
                </label>
                <div class="w-full">
                  <date-picker
                    v-model="$v.performanceReview.review_deadline.$model"
                    :input-class="
                      `${
                        $v.performanceReview.review_deadline.$error
                          ? 'border-red-500'
                          : ''
                      } 
                      border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent`
                    "
                    placeholder="Deadline für Gespräch eingeben"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col w-full m-auto"
          v-show="wizard.currentStep == 4"
        >
          <div
            v-for="(t, index) in filteredTemplate"
            :key="`major-${index}`"
            class="flex justify-between mb-8"
          >
            <div class="flex flex-col w-full space-y-4">
              <div
                class="flex flex-col md:flex-row justify-between md:space-x-2 space-y-2 md:space-y-0 mb-8 md:border-b md:pb-8 overflow-y-scroll"
              >
                <div class="w-full md:w-1/2">
                  <p class="text-sm font-bold">{{ t.name }}</p>
                  <p class="text-xs font-light py-1" v-html="t.description"></p>
                </div>

                <div class="w-full md:w-1/2">
                  <div
                    class="flex flex-col text-xs font-medium text-gray-600 w-full"
                  >
                    <ul
                      class="flex flex-col w-full list-none space-y-4"
                      v-if="t.attributemeta.length"
                    >
                      <template v-for="(attribute, sindex) in t.attributemeta">
                        <li
                          v-if="attribute.value"
                          :key="`opt-${sindex}`"
                          class="text-xs font-medium text-gray-600 border-b w-full"
                        >
                          {{ attribute.name }}
                        </li>
                      </template>
                    </ul>
                    <template v-else> Keine Daten. </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col w-full m-auto"
          v-show="wizard.currentStep == 5"
        >
          <!-- -->
          <div class="flex flex-col w-full justify-between space-y-4 mb-4">
            <div class="flex w-full justify-between">
              <h1 class="">Kunde (extern)</h1>
              <div class="w-full items-center">
                <div class="w-full">
                  <div class="flex flex-col items-center relative">
                    <div class="w-full">
                      <div
                        class="px-1 flex border border-gray-200 bg-white rounded"
                      >
                        <div class="flex flex-auto flex-wrap">
                          <div
                            class="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-green-700 bg-green-100 border border-green-300"
                            v-for="(email, index) in emails"
                            :key="`email-${index}`"
                          >
                            <div
                              class="text-xs font-normal leading-none max-w-full flex-initial"
                            >
                              {{ email }}
                            </div>
                            <div class="flex flex-auto flex-row-reverse">
                              <div @click.prevent="emails.splice(index, 1)">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="100%"
                                  height="100%"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div class="flex-1">
                            <input
                              placeholder=""
                              v-model="email"
                              @keydown="splitEmail"
                              @blur="addEmail"
                              class="bg-transparent py-2 px-1 appearance-none outline-none h-full w-full text-gray-800"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full justify-start items-center space-x-2">
              <input
                type="checkbox"
                v-model="required"
                :value="true"
                class="p-0 m-0"
              />
              <p class="p-0 m-0">
                Der/Die Mitarbeitende/r akzeptiert die Bewertung durch eine externe
                Person
              </p>
            </div>
          </div>
          <div
            v-for="(values, key, index) in externalTemplate"
            :key="`major-${index}`"
            class="flex justify-between mb-8"
          >
            <div
              class="flex flex-col md:flex-row w-full md:mb-8 space-y-4 md:space-y-0 md:border-b pb-8"
            >
              <div class="w-full md:w-1/2 flex items-start">
                <div class="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    v-model="selected[key]"
                    @change="updateChild(key)"
                  />
                  <p class="text-sm font-bold">{{ trans[key] }}</p>
                </div>
              </div>

              <div class="w-full md:w-1/2 ml-4 md:ml-0">
                <div
                  class="flex flex-col text-xs font-medium text-gray-600 w-full"
                >
                  <ul
                    class="flex flex-col w-full list-none space-y-4"
                    v-if="values.length"
                  >
                    <li
                      v-for="(value, sindex) in values"
                      :key="`opt-${index}-${sindex}`"
                      class="text-xs font-medium text-gray-600 border-b w-full flex space-x-2 items-center"
                    >
                      <template v-if="key === 'taskevaluationexternal'">
                        <input
                          type="checkbox"
                          v-model="value[_.first(_.keys(value))]['checked']"
                          @change="updateParent(key, 'task')"
                        />
                        <span>{{ value[_.first(_.keys(value))].name }}</span>
                      </template>
                      <template
                        v-if="key === 'personalityskillevaluationexternal'"
                      >
                        <input
                          type="checkbox"
                          v-model="value[_.first(_.keys(value))]['checked']"
                          @change="updateParent(key, 'personalityskill')"
                        />
                        <span>{{
                          value[_.first(_.keys(value))]['personalityskilldata']
                            .name
                        }}</span>
                      </template>
                      <template v-if="key === 'goalevaluationexternal'">
                        <input
                          type="checkbox"
                          v-model="value[_.first(_.keys(value))]['checked']"
                          @change="updateParent(key, 'goal')"
                        />
                        <span>{{ value[_.first(_.keys(value))].name }}</span>
                      </template>
                    </li>
                  </ul>
                  <template v-else>
                    <div class="flex space-x-2">Keine Daten</div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
      </div>

      <div class="mt-5 sm:mt-6 flex justify-between items-center">
        <a
          href="#"
          @click.prevent="updateCurrent(-1)"
          class="flex justify-center rounded border border-transparent shadow-sm px-2 py-1 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
          v-if="wizard.currentStep > 1"
          >Zurück</a
        >
        <template v-if="wizard.currentStep < wizard.steps.length">
          <span class="w-px"></span>
          <a
            href="#"
            @click.prevent="updateCurrent(1)"
            class="flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
            >Weiter</a
          >
        </template>
        <a
          href="#"
          @click.prevent="save"
          class="flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
          v-else
          >Speichern</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { xhr } from '@/utils/axios'
import { required } from 'vuelidate/lib/validators'
import { getAll as getAllEmployee } from '@/services/employee'

const tempPerformanceReview = {
  template: '',
  employee: '',
  supervisor: '',
  evaluation_period: '',
  evaluation_period_start: '',
  evaluation_period_end: '',
  preparation_deadline: '',
  review_deadline: '',
  evaluationexternal: [
    {
      email: '',
      taskevaluationexternal: [],
      personalityskillevaluationexternal: [],
      goalevaluationexternal: [],
    },
  ],
}

export default {
  name: 'new-performance-review',
  props: ['payload'],
  data() {
    return {
      trans: {
        taskevaluationexternal: 'Aufgaben',
        personalityskillevaluationexternal: 'Persönlichkeitseigenschaften',
        goalevaluationexternal: 'Ziele',
      },
      required: false,
      template: [],
      templates: [],
      externalTemplate: [],
      employees: [],
      performanceReview: {},
      roleprofile: {},
      personal: {},
      emails: [],
      email: '',
      scales: [],
      selected: {},
      wizard: {
        currentStep: 1,
        steps: [
          {
            id: 1,
            title: 'Vorlage auswählen',
          },
          {
            id: 2,
            title: 'Teilnehmende',
          },
          {
            id: 3,
            title: 'Zeitplan',
          },
          {
            id: 4,
            title: 'Vorschau',
          },
          {
            id: 5,
            title: 'Externes Feedback',
          },
        ],
      },
    }
  },
  validations() {
    return {
      required: {
        required,
      },
      performanceReview: {
        template: {
          required,
        },
        employee: {
          required,
        },
        supervisor: {
          required,
        },
        evaluation_period: {
          required,
        },
        preparation_deadline: {
          required,
        },
        review_deadline: {
          required,
        },
        evaluationexternal: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.performanceReview = _.cloneDeep(tempPerformanceReview)

      xhr.get(`/performance-evaluation/template/`).then(response => {
        if (response.status === 200) this.templates = response.data.results
      })

      getAllEmployee().then(response => {
        if (response.status === 200) {
          this.employees = _.map(response.data, employee => {
            return {
              ...employee,
              name: `${employee.personal.first_name} ${employee.personal.last_name}`,
            }
          })
        }
      })

      xhr.get(`/performance-evaluation/setting/ratingscale/`).then(response => {
        this.scales = response.data.results
      })
    },
    save() {
      if (this.canSave) {
        let externalTemplate = { ...this.externalTemplate }
        _.filter(_.keys(externalTemplate), key => {
          externalTemplate[key] = _.filter(
            externalTemplate[key],
            k =>
              (k.task && k.task.checked) ||
              (k.personalityskill && k.personalityskill.checked) ||
              (k.goal && k.goal.checked)
          )
        })

        const performanceReview = {
          ...this.performanceReview,
          template: this.performanceReview.template.id,
          evaluation_period_start: _.first(
            this.performanceReview.evaluation_period
          ),
          evaluation_period_end: _.last(
            this.performanceReview.evaluation_period
          ),
          employe: {
            personal: this.performanceReview.employee.personal.id,
          },
          supervisor: {
            personal: this.performanceReview.supervisor.personal.id,
          },
          evaluationexternal: [
            { ...externalTemplate, email: this.emails.join(',') },
          ],
        }

        this.$forceUpdate()

        if (!_.first(performanceReview.evaluationexternal).email)
          performanceReview.evaluationexternal = []
        xhr
          .post(`/performance-evaluation/`, performanceReview, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => {
            if (response.status === 201) {
              // // this.$toast.success(response.data.message)
              this.$emit('close-modal', { show: false })
            }
          })
      } else {
        this.$toast.error(
          'Bitte bestätigen Sie die Bewertung durch externe eine Person'
        )
      }
    },
    updateCurrent(step) {
      const validated = [
        {
          key: ['template'],
          value: this.$v.performanceReview.template.$invalid,
        },
        {
          key: ['employee', 'supervisor'],
          value:
            this.$v.performanceReview.employee.$invalid ||
            this.$v.performanceReview.supervisor.$invalid,
        },
        {
          key: ['evaluation_period', 'preparation_deadline', 'review_deadline'],
          value:
            this.$v.performanceReview.evaluation_period.$invalid ||
            this.$v.performanceReview.preparation_deadline.$invalid ||
            this.$v.performanceReview.review_deadline.$invalid,
        },
        {
          key: [],
          value: false,
        },
        {
          key: [],
          value: false,
        },
      ]

      const validation = validated[this.wizard.currentStep - 1]
      const invalid = validation.value

      validation.key.forEach(v => this.$v.performanceReview[v].$touch())

      if (!invalid) {
        const newValue = {
          currentStep: this.wizard.currentStep + step,
          steps: this.wizard.steps,
        }

        this.wizard = newValue
      }
    },
    addEmail() {
      if (this.email !== '') this.emails.push(this.email)

      this.email = ''
    },
    splitEmail(e) {
      if (e.key === ',' || e.key === 'Tab' || e.key === 'Enter') {
        e.preventDefault()
        this.emails.push(this.email)
        this.email = ''
      }
    },
    getTemplate(id) {
      xhr.get(`/performance-evaluation/template/${id}`).then(response => {
        if (response.status === 200)
          this.template = response.data.templateattribute
      })
    },
    getExternalTemplate(id) {
      xhr
        .get(
          `/performance-evaluation/external-evaluation-items/?personal_id=${id}`
        )
        .then(response => {
          this.externalTemplate = response.data.data
        })
    },
    updateParent(key, skey) {
      this.selected[key] = _.some(
        _.map(this.externalTemplate[key], `${skey}.checked`),
        Boolean
      )
    },
    updateChild(key) {
      _.map(this.externalTemplate[key], child => {
        const skey = _.keys(child)
        child[skey].checked = this.selected[key]
      })
    },
  },
  computed: {
    filteredTemplate() {
      return _.filter(this.template, t => t.value)
    },
    supervisors() {
      return _.filter(
        this.employees,
        employee => this.performanceReview.employee.id !== employee.id
      )
    },
    canSave() {
      return this.emails.length ? this.required : true
    },
  },
  watch: {
    value: function(n) {
      if (n) this.performanceReview = { ...n }
    },
    create: function(n) {
      if (!n) {
        this.wizard.currentStep = 1
      }
    },
    evaluation_period: function(n) {
      this.performanceReview.evaluation_period_start = n[0] ? n[0] : ''
      this.performanceReview.evaluation_period_end = n[1] ? n[1] : ''
    },
    'performanceReview.employee': function(n) {
      // this.performanceReview.PerformanceReviewExtern.performance_review_extern_data = {}
      if (n) {
        this.getExternalTemplate(n.personal.id)
        // this.getDependencies(n.roleprofile)
        // this.personal = n.personal
      }
    },
    'performanceReview.template': function(n) {
      if (n) {
        this.getTemplate(n.id)
      }
    },
  },
}
</script>
